.c-input {
  input[type="text"],
  select,
  textarea {
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    border: 2px solid $light-grey;
    border-radius: 2px;
    background: white;
    appearance: none;
    padding: 0.5rem 0.75rem;
    margin-bottom: 1rem;

    &:focus {
      outline: 0;
      border-color: $medium-grey;
      // box-shadow: 0 0 0 1px rgba($medium-grey, 1);
    }
  }

  textarea {
    resize: none;
    line-height: 1.6;
    min-height: 10rem;

    @media (min-width: 768px) {
      min-height: 20rem;
    }
  }
}
