.maintenance {
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  display: grid;
  height: 100vh;
  line-height: 1.5;
  place-items: center;

  .maintenance-dialog {
    margin: 1rem;
    padding: 1rem;
  }
}
