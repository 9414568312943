.c-footer {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 1px solid $medium-grey;
  padding: 1rem;
}
