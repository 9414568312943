/* Fonts */
$font-family-body: "Inter", sans-serif;

$font-size-base: 1rem;
$font-size-small: (0.875 * $font-size-base);
$font-size-large: (1.125 * $font-size-base);

$font-weight-normal: 400;
$font-weight-semi-bold: 600;

/* Colors */
$color-palette: (
  primary: #4e7954,
  error: #9e4141,
);

$light-grey: #c0c5ce;
$medium-grey: #65737e;
$dark-grey: #343d46;

$anchor-color: rgb(60, 107, 138);

$text-color: #1f1f1f;
