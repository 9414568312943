.c-header {
  background: $dark-grey;
  margin-bottom: 1rem;

  &__container {
    align-items: center;
    display: flex;
    height: 3.5rem;
  }

  &__logo {
    display: block;
    text-decoration: none;

    img {
      width: 192px;
      height: 28px;
    }
  }
}
