.c-poule {
  list-style-position: inside;
  padding-left: 0;
  margin: 0;

  > li {
    padding: 0.25rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $medium-grey;

    > span {
      margin: 0 0.25rem;
      color: $text-color;
    }
  }
}
