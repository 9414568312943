.c-button {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: inherit;
  font-size: inherit;
  font-weight: $font-weight-semi-bold;
  line-height: 1.25;
  border: 0;
  border-radius: 2px;
  padding: 0.75rem 1rem;
  appearance: none;
  cursor: pointer;
  touch-action: manipulation;
  background: map-get($color-palette, primary);
  color: white;
  transition: background-color 0.1s ease-out;

  > svg {
    width: 1.25em;
    height: 1.25em;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--expanded {
    display: flex;
    width: 100%;
  }

  &[disabled],
  &--disabled {
    opacity: 0.7;
    cursor: not-allowed;
    filter: grayscale(0.25);
  }

  &:hover:enabled {
    background: mix(black, map-get($color-palette, primary), 10);
  }

  // &--small {
  //   font-size: $font-size-small;
  //   padding: 0.5rem 0.75rem;

  //   > svg {
  //     width: 1.25em;
  //     height: 1.25em;
  //     margin-left: 0;
  //     margin-right: 0.5rem;
  //   }
  // }
}
