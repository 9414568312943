.fade-enter {
  opacity: 0;

  .c-modal__dialog {
    transform: scale(1) translateY(-1rem);
  }
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease;

  .c-modal__dialog {
    transform: scale(1);
    transition: transform 200ms ease;
  }
}

.fade-exit {
  opacity: 1;

  .c-modal__dialog {
    transform: scale(1);
  }
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;

  .c-modal__dialog {
    transform: scale(0.95);
    transition: transform 200ms ease;
  }
}
