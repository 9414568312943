.c-group-option {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + label {
      display: flex;
      align-items: center;
      border: 2px solid $light-grey;
      border-radius: 2px;
      padding: 1rem;
      transition: border-color 0.1s ease;
      cursor: pointer;

      &:hover {
        border-color: $medium-grey;
      }

      &::before {
        display: block;
        width: 12px;
        height: 12px;
        content: "";
        border: 2px solid $light-grey;
        border-radius: 50%;
        background: $light-grey;
        margin-right: 1rem;
      }
    }

    &:checked {
      + label {
        background: mix(white, $dark-grey, 95);
        border-color: $dark-grey;
        position: sticky;
        bottom: 0;

        &::before {
          background: $dark-grey;
        }
      }
    }
  }

  small {
    color: $medium-grey;
    // text-transform: uppercase;
    font-weight: $font-weight-semi-bold;
    font-size: 0.75em;
    flex: 1 0 0%;
  }
}
