.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  &__item {
    width: 100%;
    max-width: (100%);
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 768px) {
      max-width: (100% / 3);
    }
  }
}
