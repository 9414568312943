/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.c-range-target,
.c-range-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.c-range-target {
  position: relative;
}

.c-range-base,
.c-range-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements. */
.c-range-connects {
  overflow: hidden;
  z-index: 0;
}

.c-range-connect,
.c-range-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.c-range-connect {
  height: 100%;
  width: 100%;
}

.c-range-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction */
.c-range-txt-dir-rtl.c-range-horizontal .c-range-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
.c-range-vertical .c-range-origin {
  width: 0;
}

.c-range-horizontal .c-range-origin {
  height: 0;
}

.c-range-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.c-range-touch-area {
  height: 100%;
  width: 100%;
}

.c-range-state-tap .c-range-connect,
.c-range-state-tap .c-range-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.c-range-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
  */
.c-range-horizontal {
  //   height: 18px;
  height: 0.25rem;
}

.c-range-horizontal .c-range-handle {
  //   width: 34px;
  //   height: 28px;
  //   right: -17px;
  //   top: -6px;
  width: 1.5rem;
  height: 1.5rem;
  right: -0.75rem;
  top: calc(-0.75rem + 0.125rem);
}

.c-range-vertical {
  width: 0.5rem;
}

.c-range-vertical .c-range-handle {
  //   width: 28px;
  //   height: 34px;
  //   right: -6px;
  //   top: -17px;
  width: 1.25rem;
  height: 1.25rem;
}

.c-range-txt-dir-rtl.c-range-horizontal .c-range-handle {
  left: -17px;
  right: auto;
}

/* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
.c-range-target {
  //   background: #fafafa;
  //   border-radius: 4px;
  //   border: 1px solid #d3d3d3;
  //   box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
  background: $light-grey;
  border-radius: 2px;
  margin: 1.25rem 0;
}

.c-range-connects {
  border-radius: 3px;
}

.c-range-connect {
  background: map-get(
    $color-palette,
    primary
  ); //$medium-grey; // map-get($color-palette, primary);
}

/* Handles and cursors;
  */
.c-range-draggable {
  cursor: ew-resize;
}

.c-range-vertical .c-range-draggable {
  cursor: ns-resize;
}

.c-range-handle {
  //   border: 1px solid #d9d9d9;
  //   border-radius: 3px;
  //   background: #fff;
  //   cursor: default;
  //   box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
  border: 1px solid mix(black, white, 20);
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 3px 0 rgba($dark-grey, 0.25);
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

.c-range-active {
  //   box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
  outline: none;
}

/* Disabled state;
  */

[disabled] .c-range-connect {
  background: #b8b8b8;
}

[disabled].c-range-target,
[disabled].c-range-handle,
[disabled] .c-range-handle {
  cursor: not-allowed;
}

/* Pips */
/* Base;
 *
 */
.c-range-pips,
.c-range-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.c-range-pips {
  position: absolute;
  color: #999;
}

/* Values;
  *
  */
.c-range-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.c-range-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
  *
  */
.c-range-marker {
  position: absolute;
  background: #ccc;
}
.c-range-marker-sub {
  background: #aaa;
}
.c-range-marker-large {
  background: #aaa;
}

/* Horizontal layout;
  *
  */
.c-range-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.c-range-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);

  .c-range-rtl & {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
  }
}

.c-range-marker-horizontal.c-range-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.c-range-marker-horizontal.c-range-marker-sub {
  height: 10px;
}
.c-range-marker-horizontal.c-range-marker-large {
  height: 15px;
}

/* Vertical layout;
  *
  */
.c-range-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.c-range-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;

  .c-range-rtl & {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
  }
}

.c-range-marker-vertical.c-range-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.c-range-marker-vertical.c-range-marker-sub {
  width: 10px;
}
.c-range-marker-vertical.c-range-marker-large {
  width: 15px;
}
