.c-alert {
  background: mix(white, #c0c5ce, 90);
  border-radius: 2px;
  display: flex;
  font-size: $font-size-small;
  font-weight: $font-weight-semi-bold;
  line-height: 1.5;
  padding: 1.25rem;

  &__icon {
    flex: 0 0 1.25rem;
    margin-right: 1rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__content {
    flex: 1 1 0;
  }

  &--error {
    color: map-get($color-palette, error);
    margin-bottom: 1rem;
  }
}
