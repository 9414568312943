.c-card {
  background: white;
  box-shadow: 0 1px 2px 0 rgba($light-grey, 0.5);
  border: 2px solid $light-grey;
  border-radius: 2px;
  margin-bottom: 1rem;
  height: calc(100% - 1rem);

  @media (min-width: 768px) {
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
  }

  &__header {
    padding: 0.75rem 1rem;
    background: mix(white, $light-grey, 75);
    border-bottom: 2px solid $light-grey;
  }

  &__content {
    padding: 0.75rem 1rem;
    // font-size: 0.875rem;
  }
}
