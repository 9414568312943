.c-modal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.6);

  &--center {
    align-items: center;
  }

  &__dialog {
    background: white;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    border-radius: 2px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    padding: 1.5rem 1.5rem 0;
    position: relative;
    overflow-y: auto;
    max-height: 100%;

    .c-modal--global & {
      padding-bottom: 1.5rem;
    }
  }

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(white, 1),
      rgba(white, 1) 85%,
      rgba(white, 0)
    );
    padding: 0.5rem 0 1.5rem;
    backface-visibility: hidden;
  }

  .c-button + .c-button {
    margin-top: 1rem;
  }
}
