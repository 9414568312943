@import "./settings.scss";

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background: mix(white, $light-grey, 90);
  color: $text-color;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin: 0 0 0.75em;
}

h1 {
  font-size: 1.5rem;
}

strong {
  font-weight: bolder;
}

a {
  color: $anchor-color;
}

p {
  line-height: 1.4;
  margin: 0.75em 0;
}

img,
svg {
  border: 0;
  vertical-align: middle;
}

/* Objects */
@import "./scss/objects/container";
@import "./scss/objects/grid";
@import "./scss/objects/wrapper";

/* Components */
@import "./scss/components/actions";
@import "./scss/components/alert";
@import "./scss/components/button";
@import "./scss/components/card";
@import "./scss/components/control-button";
@import "./scss/components/footer";
@import "./scss/components/form";
@import "./scss/components/group-option";
@import "./scss/components/grouping-list";
@import "./scss/components/header";
@import "./scss/components/input";
@import "./scss/components/maintenance";
@import "./scss/components/modal";
@import "./scss/components/poule";
@import "./scss/components/range";

/* Transitions */
@import "./scss/transitions/fade";
@import "./scss/transitions/list";

/* Animations */
@import "./scss/animations/spin";
