.c-actions {
  background: mix(white, $light-grey, 75);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;

  &__container {
    display: flex;
  }

  .c-header + & {
    margin-top: -1rem;
  }

  .c-button:first-child {
    margin-right: 0.5rem;
  }

  @media (max-width: 767px) {
    .c-button {
      &:first-child {
        flex: 1 0 0;
      }

      &:last-child {
        flex: 0 1 auto;
      }
    }
  }
}
