.c-control-button {
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  border-radius: 2px;
  background: mix(white, $light-grey, 75);
  padding: 0.5rem;
  transition: background-color 0.1s ease;
  cursor: pointer;

  &:hover {
    background: mix(white, $light-grey, 60);
  }

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
}
